<template>
  <div>
    <div class="handle-box">
      <el-input v-model="pagination.searchKey" placeholder="违禁词" class="handle-input mrb10" clearable></el-input>
      <el-button type="primary" icon="el-icon-search" @click="searchForbiddenWord()">搜索</el-button>
      <el-button type="success" @click="openDialog(null,0)">添加</el-button>
    </div>
    <el-table :data="prohibitedWordsList" border class="table" header-cell-class-name="table-header">
      <el-table-column fixed="right" header-align="center" align="center" min-width="150" label="操作">
        <template slot-scope="scope" >
          <!--          <el-button type="text" size="small" v-if="scope.row.allocation === 0" @click="useExpenseUser(scope.row)">分配公司</el-button>-->
          <!--          <el-button type="text" style="color:#1d953f" size="small" v-if="scope.row.allocation === 1" >已分配</el-button>-->
          <el-button type="text" size="small" @click="openDialog(scope.row,1)">编辑</el-button>
          <el-button type="text" size="small" @click="deleteProWords(scope.row.id)" >删除</el-button>
        </template>
      </el-table-column>
      <el-table-column prop="id" label="ID" width="55" align="center"></el-table-column>
      <el-table-column prop="word" label="违禁词" align="center"></el-table-column>
      <!--        <el-table-column prop="phoneNumber" label="手机号" align="center"></el-table-column>-->
      <!--        <el-table-column prop="email" label="邮箱" align="center"></el-table-column>-->


    </el-table>
    <el-dialog :title="type===0?'添加关键词':'修改关键词'" :visible.sync="dialogVisible" width="30%">
      <input v-model="row.word" type="text" placeholder="请输入关键词" style="width: 100%;height: 40px;">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="addOrUpdateProWords(type,row)">确 定</el-button>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "prohibitedWordsList",
  data() {
    return {
      row: {
          "id": null,
          "word": "",
          "createdBy": null,
          "createdTime": null,
          "updateBy": null,
          "updateTime": null
      },
      type: 0,
      dialogVisible: false,
      pagination: {
        current: 1,
        size: 10,
        total: 0,
        searchKey: "",
        recommendStatus: null,
        sortId: null,
        labelId: null
      },
      prohibitedWordsList: []
    };
  },
  created() {
    this.getProhibitedWordsList();
  },
  methods:{
    openDialog(row,type) {
      this.dialogVisible = true
      this.type = type
      if (row!==null){
        this.row = row
      }
      // this.addOrUpdateProWords(row,type)
    },
    addOrUpdateProWords(type){
      // 添加禁用词
      if (type===0){
        this.$http
          .post("/prohibitedWords/add", this.row)
          .then(res => {
            if (res.code === 200 && res.data !=='已存在') {
              this.prohibitedWordsList = res.data.records;
              this.pagination.total = res.data.total;
            } else {
              this.$message.error(res.data);

            }
          });
        this.dialogVisible = false
      }
      // 编辑禁用词
      if (type===1){

        // 编辑禁用词
        this.$http
          .post("/prohibitedWords/update", this.row)
          .then(res => {
            if (res.code === 200) {
              this.prohibitedWordsList = res.data.records;
              this.pagination.total = res.data.total;
            }
            else {
              this.$message.error(res.data.msg);
            }
          });
        this.dialogVisible = false
      }
    },

    getProhibitedWordsList(){
      this.$http
        .post("/prohibitedWords/list", this.pagination)
        .then(res => {

          if (res.code === 200) {
            this.prohibitedWordsList = res.data.records;
            this.pagination.total = res.data.total;
          }
          else {
            this.$message.error(res.data.msg);
          }
        });
    },
    searchForbiddenWord(){
      // 搜索禁用词
      this.$http
        .post("/prohibitedWords/list", this.pagination)
        .then(res => {
          if (res.code === 200) {
            this.prohibitedWordsList = res.data.records;
            this.pagination.total = res.data.total;
          }
          else {
            this.$message.error(res.data.msg);
          }
        });
    },
    deleteProWords(id){
      // todo 删除禁用词
      this.$confirm('确认删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'success',
        center: true
      }).then(() => {
        this.$http
          .post("/prohibitedWords/delete?id="+id )
          .then(res => {
            if (res.code === 200) {
              this.$message.success("删除成功");
              this.getProhibitedWordsList();
            }
            else {
              this.$message.error(res.data.msg);
            }
          })
      })

    }
  }

}
</script>

<style scoped>

.handle-box {
  margin-bottom: 20px;
}

.handle-select {
  width: 120px;
}

.handle-input {
  width: 160px;
  display: inline-block;
}

.table {
  width: 100%;
  font-size: 14px;
}

.mrb10 {
  margin-right: 10px;
  margin-bottom: 10px;
}

.table-td-thumb {
  display: block;
  margin: auto;
  width: 40px;
  height: 40px;
}

.pagination {
  margin: 20px 0;
  text-align: right;
}

.el-switch {
  margin: 5px;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 40px;
  height: 40px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 40px;
  height: 40px;
  display: block;
}

.el-input-img{
  /*比其他输入框宽度少50px*/
  width: calc(100% - 50px);
  height: 40px;
  display: block;
}
</style>
